import SearchInput from 'components/forms/SearchInput'
import React from 'react'
import { useHistory } from 'react-router-dom'

const NavSearchBar = () => {
  const history = useHistory()
  const submitSearch = (value: string) => {
    if (value.length > 1) history.push('/search/' + value)
  }

  return (
    <div className="order-0 md:order-3 flex flex-grow border-gray-850 md:mx-4 md:px-4" style={{ maxWidth: '25rem', minWidth: 0 }}>
      <SearchInput placeholder="输入地图名称、玩家昵称、SteamID进行搜索" submit={submitSearch} height="31px" />
    </div>
  )
}

export default NavSearchBar
