import { UserContext } from 'context/UserContext'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  switchTheme(darkmode: boolean): void
  darkmode: boolean
}

const Footer = (props: Props) => {
  const [checked, setChecked] = useState(props.darkmode)
  const userCtx = useContext(UserContext)

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
    props.switchTheme(e.target.checked)
  }

  return (
    <footer className="flex justify-center items-center">
      <div className="mr-4 hidden text-sm sm:inline-block">
        &copy; CSGOKZ 2022 - <a href="https://www.kzstats.com/" target="_blank">Powered by KZStats</a>
      </div>
      <div className="mr-4">
        <a href="https://jq.qq.com/" target="_blank">
          CSGOKZ QQ群
        </a>
      </div>
    </footer>
  )
}
export default Footer
