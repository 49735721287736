const textLimiter = (content: string, limit?: number) => {
  if (!content) return
  if (content.length > (limit || 25)) return `${content.slice(0, 20)}...`
  return content
}

const runtimeFormat = (time: number) => {
  if (!time) return
  let timeStr = new Date(time * 1000)
    .toISOString()
    .split('T')[1]
    .replace('Z', '')
  if (timeStr.substr(0, 2) === '00') timeStr = timeStr.slice(3)

  return timeStr
}

const timeAgoFormat = (datetime: string) => {
  const dt =
    Date.parse(datetime) - new Date(datetime).getTimezoneOffset() * 60000
  let result = (Date.now() - dt) / 1000
  let unit = '秒'

  if (result > 59) {
    result = result / 60
    unit = '分'
    if (result > 59) {
      result = result / 60
      unit = '小时'
      if (result > 23) {
        result = result / 24
        unit = '天'
        if (result > 6) {
          result = result / 7
          unit = '周'
        }
      }
    }
  }
  if (result > 1.49) unit += ''

  return `${result.toFixed(0)} ${unit}前`
}

const difficultyToText = (difficulty: number): string => {
  switch (difficulty) {
    case 1:
      return '非常简单'
    case 2:
      return '简单'
    case 3:
      return '中级'
    case 4:
      return '困难'
    case 5:
      return '非常困难'
    case 6:
      return '极限'
    case 7:
      return '死亡'
    default:
      return '未知'
  }
}

const sortByColumn = (
  data: [],
  column: string,
  type: string,
  reverse?: boolean
): [] => {
  const sorted = data.sort((a, b) => {
    return a[column] - b[column]
  })
  if (reverse) sorted.reverse()
  return sorted
}

export {
  textLimiter,
  runtimeFormat,
  timeAgoFormat,
  difficultyToText,
  sortByColumn,
}
