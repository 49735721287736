import { ModeContext } from 'context/ModeContext'
import React, { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

const NavModeSelect = () => {
  const { state: modeState, dispatch: modeDispatch } = useContext(ModeContext)

  const [mode, setMode] = useState(modeState.kzMode)
  const [tick, setTick] = useState(modeState.tickrate)
  const [showCopyBox, setShowCopyBox] = useState(false)
  let copyableUrl = `${window.location.href.split('?')[0]}?mode=${mode}`
  if (mode === 'kz_timer') copyableUrl += `&tick=${tick}`

  const changeMode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const tr = event.target.value !== 'kz_timer' ? '128' : modeState.tickrate
    localStorage.setItem('kzMode', event.target.value)
    localStorage.setItem('tickrate', tr)
    modeDispatch(event.target.value, tr)
  }

  const changeTickrate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    modeDispatch(modeState.kzMode, event.target.value)
    localStorage.setItem('tickrate', event.target.value)
  }

  const toggleCopyBox = () => {
    const current = showCopyBox
    setShowCopyBox(!showCopyBox)
    if (!current) {
      setTimeout(() => {
        copyText()
      }, 200)
    }
  }

  const copyText = () => {
    var copyText = document.querySelector('#urlField') as HTMLInputElement
    copyText?.select()
    document.execCommand('copy')
  }

  useEffect(() => {
    setMode(modeState.kzMode)
    setTick(modeState.tickrate)
  }, [modeState.kzMode, modeState.tickrate])

  return (
    <div className="order-6 flex items-center content-center justify-center flex-wrap sm:flex-no-wrap">
      <ReactTooltip />
      <div className="inline-block">
        <span className="mode-select-text">模式:</span>
        <select value={mode} onChange={changeMode}>
          <option value="kz_timer">KZTimer</option>
          <option value="kz_simple">Simple KZ</option>
          <option value="kz_vanilla">Vanilla</option>
        </select>
      </div>
      {modeState.kzMode === 'kz_timer' && (
        <div className="inline-block">
          <span className="mode-select-text">Tick:</span>
          <select value={tick} onChange={changeTickrate}>
            <option value="128">128</option>
            <option value="102">102</option>
            <option value="64">64</option>
          </select>
        </div>
      )}
    </div>
  )
}

export default NavModeSelect
