import SearchInput from 'components/forms/SearchInput'
import Table from 'components/general/Table'
import useApiRequest from 'hooks/useApiRequest'
import { Helmet } from 'react-helmet'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  match: { params: { steamid?: string } }
}

const BanListView = (props: Props) => {
  const history = useHistory()
  const [apiOptions, setApiOptions] = useState({
    limit: 100,
    steam_id: props.match.params.steamid,
  })
  const { error, loader, data } = useApiRequest('/bans', apiOptions)

  const submitSearch = (value: string) => {
    setApiOptions({ limit: 100, steam_id: value })
    history.push('/bans/' + value)
  }

  if (error) return error
  if (loader) return loader

  return (
    <div>
      <Helmet title="封禁名单" />
      <h2 className="mb-4">封禁名单</h2>
      <div>
        系统默认只显示最近100条封禁记录。您如果要查询是否被封禁，可以在下面搜索框输入SteamID进行查询。
        <br />
        您如果要了解更多信息或者申诉，请访问 <a href="https://forum.gokz.org/p/player-rules" target="_blank">GOKZ 官方论坛</a>。
      </div>
      <div className="my-4 flex flex-row w-full lg:w-1/3 items-center">
        <SearchInput placeholder="例如 STEAM_X:X:123456789" submit={submitSearch} label="Steam ID：" height="34px" />
      </div>
      <div>
        {data.length > 0 ? (
          <Table
            columns={[
              { key: 'player_name', header: '玩家', type: 'player' },
              { key: 'steam_id', header: 'Steam ID' },
              { key: 'ban_type', header: '原因', type: 'ban_type' },
              { key: 'updated_on', header: '日期', type: 'datetime' },
              { key: 'expires_on', header: '到期', type: 'datetime' },
            ]}
            data={data}
            sort={{ key: 'updated_on', desc: true }}
            itemsPerPage={25}
          />
        ) : (
          <p>暂无数据.</p>
        )}
      </div>
    </div>
  )
}

export default BanListView
