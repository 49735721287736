import { BanIcon, HomeIcon, JumpIcon, ListIcon, MapIcon, PersonIcon, ServerIcon } from 'components/icons'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import NavModeSelect from './NavModeSelect'
import NavProfileBlock from './NavProfileBlock'
import NavSearchBar from './NavSearchBar'

function NavBar() {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  const closeMenu = () => {
    setShowMenu(false)
  }

  return (
    <nav className="nav-main">
      <div className="hidden items-center flex-shrink-0 text-white mr-8 nav-logo">
        <NavLink to="/" className="nav-logo">
          <span className="font-semibold text-2xl tracking-tight text-logo">KZCN.CSGODH.COM</span>
        </NavLink>
      </div>
      <NavSearchBar />
      <div className="order-2 md:hidden ml-4 mobile-top">
        <button onClick={toggleMenu} className="text-gray-500 border-gray-750 p-2 rounded">
          <ListIcon />
        </button>
      </div>
      <div id="navmenu" className={!showMenu ? 'hidden md:flex' : 'flex'}>
        <div className="flex flex-col md:flex-row md:flex-grow md:pl-2">
          <NavLink to="/" onClick={closeMenu}>
            <HomeIcon />
            首页
          </NavLink>
          <NavLink activeClassName="font-bold" onClick={closeMenu} to="/maps">
            <MapIcon />
            地图
          </NavLink>
          <NavLink activeClassName="font-bold" onClick={closeMenu} to="/players">
            <PersonIcon />
            玩家
          </NavLink>
          <NavLink activeClassName="font-bold" onClick={closeMenu} to="/jumpstats">
            <JumpIcon />
            跳跃数据
          </NavLink>
          <a href="https://www.csgodh.com/kz.html" target="_blank">
            <ServerIcon />
            服务器
          </a>
          <NavLink activeClassName="font-bold" onClick={closeMenu} to="/bans">
            <BanIcon />
            封禁名单
          </NavLink>
        </div>
      </div>
      <div className={`${!showMenu ? 'hidden' : 'flex'} w-full md:w-auto md:flex mode-last order-5 mt-2 pt-2 md:p-0 md:m-0`}>
        <NavModeSelect />
      </div>
    </nav>
  )
}

export default NavBar
