import Panel from 'components/general/PanelGet'
import useApiRequest from 'hooks/useApiRequest'
import React from 'react'

const GetMe = () => {
  const { error, loader, data } = useApiRequest('https://kzget.csgodh.com/sponsor.php', null, true)

  if (loader) return loader
  if (error) return error

  const renderHeader = () => {
    return (
      <>
        赞助
      </>
    )
  }

  return (
    <Panel header={renderHeader()}>
      {data.length === 0 && <p>There was a problem retrieving the announcement list.</p>}
      {data.map((newspost: { message: string; footer: string }, i: number) => (
        <div className="news-block" key={i}>
          <div className="text-xl font-bold">{newspost.footer}</div>
          <div
            className="news-post"
            dangerouslySetInnerHTML={{
              __html: newspost.message,
            }}
          ></div>
        </div>
      ))}
    </Panel>
  )
}

export default GetMe
