import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { KZMap } from 'types'
import useApiRequest from '../../../hooks/useApiRequest'
import MapListGrid from './MapListGrid'
import MapListTable from './MapListTable'

const MapListView = () => {
  const timer = useRef(0)
  const [apiOptions] = useState({
    is_verified: true,
    limit: 1000,
  })
  const { error, loader, data } = useApiRequest('/maps', apiOptions)
  const [nameFilter, setNameFilter] = useState('')
  const [difficultyFilter, setDifficultyFilter] = useState('0')
  const [isFancy, setIsFancy] = useState(true)
  const [filtered, setFiltered] = useState<KZMap[]>([])

  // Update filter 0.6secs after user has stopped typing
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer.current)
    const val = e.target.value
    timer.current = window.setTimeout(() => {
      setNameFilter(val)
    }, 600)
  }

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDifficultyFilter(e.target.value)
  }

  useMemo(() => {
    let temp = data
    if (nameFilter.length > 0) {
      temp = temp.filter((m: KZMap) => {
        return m.name.includes(nameFilter || '')
      })
    }
    if (difficultyFilter !== '0') {
      temp = temp.filter((m: KZMap) => {
        return (
          difficultyFilter === '0' ||
          m.difficulty === parseInt(difficultyFilter)
        )
      })
    }
    setFiltered(temp)
  }, [nameFilter, difficultyFilter, data])

  useEffect(() => {
    setFiltered(data)
  }, [data])

  if (error) return error
  if (loader) return loader

  return (
    <div>
      <Helmet title="KZ地图" />
      <h2 className="mb-5">
      地图<small> - 总计：{data.length} 张</small>
      </h2>
      <div className="flex flex-wrap items-center mb-8">
        <div className="mr-4 mb-4 md:mb-0 lg:mb-0">
          地图名：
          <input type="text" onChange={handleInput} className="ml-2" />
        </div>
        <div className="mr-4">
          难度：
          <select
            onChange={handleSelect}
            value={difficultyFilter}
            className="ml-2"
          >
            <option value="0">所有</option>
            <option value="1">非常简单</option>
            <option value="2">简单</option>
            <option value="3">中级</option>
            <option value="4">困难</option>
            <option value="5">非常困难</option>
            <option value="6">极限</option>
            <option value="7">死亡</option>
          </select>
        </div>
        <div>
          图片：
          <input
            type="checkbox"
            className="ml-2"
            checked={isFancy}
            onChange={() => {
              setIsFancy(!isFancy)
            }}
          />
        </div>
      </div>
      {isFancy ? (
        <MapListGrid maps={filtered || data} />
      ) : (
        <MapListTable maps={filtered || data} />
      )}
    </div>
  )
}

export default MapListView
