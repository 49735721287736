import React from 'react'

interface Props {
  message?: string
  type?: number
  local?: boolean
}

const ErrorHandler = (props: Props) => {
  if (props.type === 404)
    return (
      <div>
        <h1>404</h1> 找不到访问的页面.
      </div>
    )

  return (
    <div className="my-4">
      <h2>错误</h2>
      {props.local ? (
        <p>无法连接到KZ全球数据中心！</p>
      ) : (
        <p>请刷新页面重试。</p>
      )}
      <p>错误信息: {props.message}</p>
    </div>
  )
}

export default ErrorHandler
